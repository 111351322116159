import Alpine from 'alpinejs';
import Swiper from "swiper/bundle";
import { EffectFade } from 'swiper/modules';

Alpine.data('TestimonialSlider', () => {
	return {
		swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    modules: [EffectFade],
                    loop: true,
                    spaceBetween: 100,
                    pagination: {
                        el: this.$refs.swiperPagination,
                        clickable: true,
                        type: 'bullets',
                    },
                    fadeEffect: { 
                        crossFade: true,
                    },
                    effect: 'fade',
                    slidesPerView: 1,
                    autoplay: {
                        delay: 7000,
                    },
                    navigation: {
                        nextEl: this.$refs.swiperNext,
                        prevEl: this.$refs.swiperPrev,
                    },
                });
            }, 0);
        },
	};
})